import React from "react"
import {graphql} from "gatsby"
import Seo from "../components/molecules/Seo";
import PrismicRichText from "../components/atoms/PrismicRichText";
import Header from "../components/molecules/Header";
import Container from "../components/atoms/Container";
import styled from "styled-components";
import ColorScheme from "../components/atoms/ColorScheme";

const ContentHolder = styled.div`
  margin: 2rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin: 4rem 0;
  }
`;

function Page({data}) {
  const {title, content, primary, secondary} = data.prismicPage.data;
  return (
    <ColorScheme primary={primary} secondary={secondary}>
      <Seo title={title.text} />
      <Header title={title.text} topColor="yellow" bottomColor="purple" />
      <Container>
        <ContentHolder>
          <PrismicRichText render={content.raw} />
        </ContentHolder>
      </Container>
    </ColorScheme>
  )
}

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    prismicPage(id: { eq: $id }) {
      data {
          primary
          secondary
          title {
            text
          }
          content {
            raw
          }
      }
    }
  }
`
